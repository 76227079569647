import { setLoginFlowState } from "@vgno/utils";
const getIdentity = () => {
  return new Promise((resolve) => {
    if (!window.Identity) {
      window.addEventListener("identity-initialized", () => {
        resolve(window.Identity);
      });
    } else {
      resolve(window.Identity);
    }
  });
};

export class VGRequireLoginElement extends HTMLElement {
  static observedAttributes = ["is-logged-in"];
  private _eventController = null as AbortController | null;
  private _internals: ElementInternals;

  constructor() {
    super();
    this._internals = this.attachInternals();
  }

  attributeChangedCallback(name: string, _: string, newValue: string) {
    if (name === "is-logged-in") {
      if (newValue === "true") {
        this._internals.states.add("is-logged-in");
        this.renderLoggedIn();
      } else {
        this._internals.states.delete("is-logged-in");
        this.renderNotLoggedIn();
      }
    }
  }

  async checkLoginState() {
    const identity = await getIdentity();
    // @ts-expect-error - isLoggedIn is a property of Identity
    const isLoggedIn = await identity.isLoggedIn();
    this.setAttribute("is-logged-in", isLoggedIn);
  }

  connectedCallback() {
    this.checkLoginState();
  }

  renderLoggedIn() {
    this._eventController?.abort();
    this.querySelector(".login-button")?.remove();
  }

  renderNotLoggedIn() {
    const template = this.querySelector(".login-template");
    if (template instanceof HTMLTemplateElement) {
      this.prepend(template.content.cloneNode(true));
    }
    this._eventController = new AbortController();
    this.querySelector(".login-button")?.addEventListener(
      "click",
      async () => {
        const identity = await getIdentity();

        // TODO: this is very custom, should be moved out
        const url = new URL(window.location.href);
        url.hash = "vg-comments";
        url.searchParams.set("utm_source", "login-flow");
        url.searchParams.set("utm_campaign", "login-wall-component");
        // @ts-expect-error - login is a method of Identity
        await identity.login({
          state: setLoginFlowState(url.toString()),
        });
      },
      {
        signal: this._eventController.signal,
      },
    );
  }
}

export const defineElement = () => {
  if (!customElements.get("vg-require-login")) {
    customElements.define("vg-require-login", VGRequireLoginElement);
  }
};
